import type { PropType } from 'vue'
import {
  FlexRender,
  type Header,
  type HeaderGroup,
  type RowData,
} from '@tanstack/vue-table'
import type { VirtualItem } from '@tanstack/vue-virtual'
import { useTableStore } from './composables'
import Pinning from './Pinning'
import SortingIcon from './SortingIcon'

export default defineComponent({
  props: {
    groups: {
      type: Array as PropType<HeaderGroup<RowData>[]>,
      required: true,
    },
    offsetHeader: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const { hasVirtualColumns, variants, getCommonStyles, displayHeader } =
      useTableStore()!

    return () => (
      <thead
        class={variants.thead()}
        style={{
          top: props.offsetHeader,
        }}
      >
        {props.groups.map((headerGroup) => {
          let headers = headerGroup.headers

          if (hasVirtualColumns.value) {
            headers = headers.filter(displayHeader)
          }

          return (
            <tr key={headerGroup.id} class={variants.theadTr()}>
              {/**fake empty column to the left for virtualization scroll padding **/}
              {hasVirtualColumns.value ? (
                <th
                  class={variants.theadTh()}
                  style={{
                    display: 'var(--virtual-padding-left-display)',
                    width: `calc(var(--virtual-padding-left) * 1px)`,
                  }}
                />
              ) : null}
              {headers.map((header) => {
                // GROUP
                const isHeaderGroup =
                  !header.isPlaceholder && header.subHeaders.length > 0

                // FEATURES
                const disableFeatures = isHeaderGroup || header.isPlaceholder

                // SORT
                const canSort = disableFeatures
                  ? false
                  : header.column.getCanSort()
                const isSorted = disableFeatures
                  ? false
                  : header.column.getIsSorted()
                const nextSortingOrder = disableFeatures
                  ? false
                  : header.column.getNextSortingOrder()

                // PIN
                const canPin = disableFeatures
                  ? false
                  : header.column.getCanPin()
                // RENDER
                const flexRenderResult = header.isPlaceholder ? null : (
                  <FlexRender
                    render={header.column.columnDef.header}
                    props={header.getContext()}
                  />
                )

                const isGroup = headerGroup.depth < props.groups.length - 1
                const groupHasData = isGroup && !header.isPlaceholder
                const hasParent = header.column.depth > 0

                return (
                  <th
                    key={header.id}
                    class={[
                      variants.theadTh({
                        isGroup,
                        groupHasData,
                        hasParent,
                      }),
                    ]}
                    colSpan={header.colSpan}
                    style={{
                      ...getCommonStyles(header.column, header),
                      width: `calc(var(--header-${header.id}-size) * 1px)`,
                    }}
                  >
                    {header.isPlaceholder ? null : (
                      <div
                        class={variants.theadThInner({
                          columnCanSort: canSort,
                        })}
                        onClick={header.column.getToggleSortingHandler()}
                      >
                        <span
                          class={[
                            'flex max-w-full shrink grow',
                            canPin &&
                              (header.column.getIsPinned()
                                ? 'w-[calc(100%-20px)]'
                                : 'group-hover/th:w-[calc(100%-20px)]'),
                          ]}
                        >
                          <VTooltip
                            class="block w-full"
                            delay={40}
                            distance={8}
                            auto-hide={false}
                            disabled={!canSort}
                          >
                            {{
                              default: () => (
                                <span
                                  class={[
                                    header.column.id !== 'select' &&
                                      'block truncate',
                                  ]}
                                >
                                  {flexRenderResult}
                                </span>
                              ),
                              popper: () => (
                                <span class="relative flex items-center gap-1 text-xs">
                                  {nextSortingOrder
                                    ? 'Sort by '
                                    : 'Stop sorting by '}
                                  <span class="font-medium">
                                    {flexRenderResult}{' '}
                                  </span>
                                  {header.column.getNextSortingOrder() !==
                                  false ? (
                                    <SortingIcon sort={nextSortingOrder} />
                                  ) : null}
                                </span>
                              ),
                            }}
                          </VTooltip>
                        </span>
                        {canPin ? <Pinning column={header.column} /> : null}
                        {header.column.getIsSorted() ? (
                          <SortingIcon sort={isSorted} />
                        ) : null}
                      </div>
                    )}
                    {header.column.getCanResize() ? (
                      <div
                        class={variants.theadThResizer({ isGroup })}
                        data-resizing={header.column.getIsResizing()}
                        onDblclick={() => header.column.resetSize()}
                        onMousedown={header.getResizeHandler()}
                        onTouchstart={header.getResizeHandler()}
                      />
                    ) : null}
                  </th>
                )
              })}
              {/**fake empty column to the left for virtualization scroll padding **/}
              {hasVirtualColumns.value ? (
                <th
                  class={variants.theadTh()}
                  style={{
                    display: 'var(--virtual-padding-right-display)',
                    width: `calc(var(--virtual-padding-right) * 1px)`,
                  }}
                />
              ) : null}
            </tr>
          )
        })}
      </thead>
    )
  },
})
